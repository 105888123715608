import Link from 'components/Link'
import React from 'react'
import Helmet from 'react-helmet'
import theme from 'styles/theme'

const NotFoundPage = () => (
  <div
    css={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '5rem 0',
      textAlign: 'center',
      minHeight: '60vh',
    }}
  >
    <Helmet title='Page Not Found — OWYN®'>
      <meta name='robots' content='noindex' />
    </Helmet>
    <h2 css={{ ...theme.h2, color: theme.colors.spinach }}>404. Page Not Found</h2>
    <p
      css={{
        maxWidth: '22em',
        margin: '2rem auto',
      }}
    >
      You deserve the best protein in the business. Pick your protein, just how you like it!
    </p>
    <Link
      to='/'
      css={{
        backgroundColor: theme.colors.tumeric,
        borderRadius: 0,
        padding: '1.5rem',
        textDecoration: 'none',
      }}
    >
      Back to Home →
    </Link>
  </div>
)

export default NotFoundPage
